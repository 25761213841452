import axios from "axios";

const baseUrl = "https://www.myhume.com.au/api/";
//const baseUrl = "https://www-test.myhume.com.au/api/";
//const baseUrl = "http://localhost:5000/api/";

export const getOrderDetails = (orderCode) => {
  return axios.get(baseUrl + 'public/order?code=' + orderCode);
}

export const getCustomerByCode = (code) => {
  return axios.post(baseUrl + 'public/customer?code=' + code);
}

export const getVerificationCode = (data) => {
  return axios.post(baseUrl + 'public/code', data);
}

export const authenticate = (data) => {
  return axios.post(baseUrl + 'public/auth', data);
}

export const getProductGroups = () => {
  return axios.get(baseUrl + 'public/product/groups');
}

export const getProductCategories = (groupId) => {
  return axios.get(baseUrl + 'public/product/categories/' + groupId);
}

export const getProductSystems = (groupId) => {
  return axios.get(baseUrl + 'public/product/systems/' + groupId);
}

export const getProductsByCategory = (categoryId) => {
  return axios.get(baseUrl + 'public/product/category/' + categoryId);
}

export const getProductsBySystem = (systemId) => {
  return axios.get(baseUrl + 'public/product/system/' + systemId);
}

export const getProductDetails = (productId) => {
  return axios.get(baseUrl + 'public/product/details/' + productId);
}

export const createOrder = (data) => {
  return axios.post(baseUrl + 'public/order', data);
}

export const createPickingNote = (salesOrderId) => {
  return axios.post(baseUrl + 'public/picking?salesOrder=' + salesOrderId);
}