import './NavHeader.css';
import {Button, Image} from "antd";
import {Header} from "antd/es/layout/layout";

const NavHeader = (props) => {
  return (
    <Header className="header">
      <Image
        className="logo"
        src="hbp-logo.jpg"
        preview={false}
        height={50}
      />
      <div className="language">
        <Button size="large" style={{ marginRight: '12px' }}>English</Button>
        <Button size="large" style={{ marginLeft: '12px' }}>中文</Button>
      </div>
    </Header>
  );
}

export default NavHeader;