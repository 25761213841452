import './CreateOrder.css';
import {Button, Card, Descriptions, Space, Table, message} from "antd";
import {useLocation, useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {PlusOutlined} from "@ant-design/icons";
import ModalProduct from "../product/ModalProduct";
import { v4 as uuidv4 } from 'uuid';
import ModalProductEdit from "../product/ModalProductEdit";
import {authenticate, createOrder} from "../../service/api";

const CreateOrder = (props) => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = state;
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [orderItemEdit, setOrderItemEdit] = useState();

  const [orderTotal, setOrderTotal] = useState({
    sqm: 0,
    weight: 0,
    items: 0
  });
  const [orderItems, setOrderItems] = useState([]);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Button size="large" onClick={() => onEditItem(record)}>Edit</Button>
          <Button size="large" danger onClick={() => onDeleteItem(record)}>Delete</Button>
        </Space>
      )
    }
  ];

  const onProductsAdded = (products) => {
    setModalVisible(false);
    const items = [...orderItems];
    products.forEach(p => {
      items.push({
        id: uuidv4(),
        code: p.product.code,
        description: p.product.name,
        quantity: p.quantity,
        weight: p.quantity * p.product.measurement?.weight,
        sqm: p.quantity * p.product.measurement?.volume
      })
    });
    setOrderItems(items);
  };

  const onDeleteItem = (item) => {
    const items = orderItems.filter(value => value.id !== item.id);
    setOrderItems(items);
  };

  const onEditItem = (item) => {
    setOrderItemEdit(item);
    setModalEditVisible(true);
  };

  const onItemEdited = (item, quantity) => {
    setModalEditVisible(false);
    if (quantity === 0) {
      const items = orderItems.filter(it => it.id === item.id);
      setOrderItems(items);

    } else {
      const items = [...orderItems];
      for (const element of items) {
        if (element.id === item.id) {
          element.quantity = quantity;
        }
      }
      setOrderItems(items);
    }
  }

  const updateOrderTotal = () => {
    let total = 0;
    let totalSqm = 0;
    let totalWeight = 0;
    orderItems.forEach((orderItem) => {
      total += orderItem.quantity;
      totalSqm += orderItem.sqm;
      totalWeight += orderItem.weight;
    });
    setOrderTotal({
      sqm: totalSqm.toFixed(1),
      weight: totalWeight.toFixed(1),
      items: total
    });
  };

  const onSubmitOrder = () => {
    setLoading(true);
    const data = {
      items: orderItems,
      user: user
    };
    console.log(data);
    createOrder(data).then(res => {
      setLoading(false);
      if (res.status !== 200 || !res.data.success) {
        message.error("An error has occurred. Please speak to our customer service.")
      } else {
        navigate('/create/success', { state: { result: res.data.data.list[0] } });
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      message.error("Network error. Please try again.");
    });
  }

  useEffect(() => {
    updateOrderTotal();
  }, [orderItems]);

  return (
    <div className="content">
      <Card
        className="content-center title-center"
        title="Create a New Order"
        style={{ width: '90vw'}}
        extra={
          <Button
            size="large"
            className="screen-button-sm"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setModalVisible(true)}
            loading={loading}
          >Add Item</Button>
        }
      >
        <Card.Grid className="order-header">
          <Descriptions
            title="Order Summary"
            bordered
            column={1}>
            <Descriptions.Item label="Account">
              {`${user?.customer?.code} - ${user?.customer?.name}`}
            </Descriptions.Item>
            <Descriptions.Item label="User">
              {user.name}
            </Descriptions.Item>
            <Descriptions.Item label="Branch">
              Yennora
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              Pick up now
            </Descriptions.Item>
            <Descriptions.Item label="Items">
              {orderTotal.items}
            </Descriptions.Item>
            <Descriptions.Item label="SQM">
              {orderTotal.sqm}
            </Descriptions.Item>
            <Descriptions.Item label="Weight">
              {orderTotal.weight} kg
            </Descriptions.Item>
          </Descriptions>
        </Card.Grid>
        <Card.Grid className="order-items">
          <Table
            dataSource={orderItems}
            rowKey={(record) => (record.id + Date.now())}
            columns={columns}
            title={() => ("Order Items")}
            pagination={{ position: 'bottomCenter', showSizeChanger: false, defaultPageSize: 5 }}
          />
        </Card.Grid>
        <div style={{ width: '100%', marginTop: '32px', marginBottom: '32px' }}>
          <Space
            style={{ marginTop: '16px' }}
            direction="horizontal"
            size="large"
          >
            <Button
              size="large"
              className="screen-button"
              onClick={() => navigate('/')}
              loading={loading}
            >Back to Home</Button>
            <Button
              size="large"
              type="primary"
              className="screen-button"
              loading={loading}
              onClick={() => onSubmitOrder()}
              disabled={orderItems == null || orderItems.length === 0}
            >Submit</Button>
          </Space>
        </div>
      </Card>
      <ModalProduct
        visible={modalVisible}
        setVisible={setModalVisible}
        onAddProducts={onProductsAdded}
      />
      <ModalProductEdit
        visible={modalEditVisible}
        setVisible={setModalEditVisible}
        orderItem={orderItemEdit}
        onItemEdited={(item, quantity) => onItemEdited(item, quantity)}
      />
    </div>
  )
}

export default CreateOrder;