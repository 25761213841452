import {Button, InputNumber, Modal} from "antd";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

const ModalProductEdit = (props) => {
  const { orderItem, visible, setVisible, onItemEdited } = props;
  const [quantity, setQuantity] = useState(orderItem?.quantity);

  useEffect(() => {
    setQuantity(orderItem?.quantity);
  }, [orderItem]);

  return (
    <Modal
      title={orderItem?.description}
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => onItemEdited(orderItem, quantity)}
      maskClosable={false}
      okButtonProps={{
        size: 'large',
        className: 'screen-button-sm'
      }}
      cancelButtonProps={{
        size: 'large',
        className: 'screen-button-sm'
      }}
      forceRender
    >
      <InputNumber
        key={'input-' + orderItem?.id}
        size="large"
        controls={false}
        autoFocus
        addonBefore={<Button type="text" icon={<MinusOutlined />} onClick={() => setQuantity(quantity === 0 ? 0 : quantity - 1)}/> }
        addonAfter={<Button type="text" icon={<PlusOutlined />} onClick={() => setQuantity(quantity + 1)} /> }
        defaultValue={0}
        min={0}
        max={100}
        disabled
        value={quantity}
      />
    </Modal>
  )
}

export default ModalProductEdit;