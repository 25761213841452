import React from "react";
import {Card, Col, Descriptions, Divider, Image, Row, Space, Table, Typography} from "antd";
import './PickingNote.css';
import moment from "moment";
import Barcode from "react-barcode/lib/react-barcode";

export class PickingNote extends React.PureComponent {

  render() {
    const { data } = this.props;
    const columns = [
      {
        title: 'Product',
        dataIndex: ['product', 'code'],
        key: ['product', 'code']
      },
      {
        title: 'Description',
        dataIndex: ['product', 'description'],
        key: ['product', 'description']
      },
      {
        title: 'KG',
        dataIndex: 'weight',
        key: 'weight'
      },
      {
        title: 'Pick',
        dataIndex: 'quantity',
        key: 'quantity'
      },
      {
        title: 'Picked',
        dataIndex: 'picked',
        key: 'picked',
        className: 'picking-box',
        render: (text, record) => (
          record.picked === 0 ? <span className='no-padding-bottom'>_________</span> : <Typography.Text>{text}</Typography.Text>
        )
      },
    ]
    return (
      <div style={{ padding: '48px' }} className="picking-note">
        <Row>
          <Col span={16}>
            <Typography.Title level={2} underline style={{ marginLeft: '16px' }}>PICKING NOTE</Typography.Title>
            <Descriptions column={2} bordered size="small">
              <Descriptions.Item label="Picking Note" labelStyle={{ fontWeight: 'bold', fontSize: 14}}>
                {data?.header?.number}
              </Descriptions.Item>
              <Descriptions.Item label="Branch" labelStyle={{ fontWeight: 'bold'}}>
                {data?.header?.branch?.code}
              </Descriptions.Item>
              <Descriptions.Item label="Date" labelStyle={{ fontWeight: 'bold'}}>
                {data?.header?.date ? moment(data?.header?.date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}
              </Descriptions.Item>
              <Descriptions.Item label="Typed By" labelStyle={{ fontWeight: 'bold'}}>
                Kiosk
              </Descriptions.Item>
              <Descriptions.Item label="Page" labelStyle={{ fontWeight: 'bold'}}>
                1/1
              </Descriptions.Item>
              <Descriptions.Item label="Order No" labelStyle={{ fontWeight: 'bold'}}>
                {data?.header?.reference}
              </Descriptions.Item>
              <Descriptions.Item label="Printed on" labelStyle={{ fontWeight: 'bold'}}>
                {moment().format('DD/MM/YY h:mm A')}
              </Descriptions.Item>
              <Descriptions.Item label="Due Date" labelStyle={{ fontWeight: 'bold'}}>
                {data?.header?.dueDate}
              </Descriptions.Item>
              <Descriptions.Item label="Pick up by" labelStyle={{ fontWeight: 'bold'}}>
                {data?.header?.particular}
              </Descriptions.Item>
              <Descriptions.Item label="Order Type" labelStyle={{ fontWeight: 'bold'}}>
                {data?.header?.orderType}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Image
              src="hbp-logo.jpg"
              className="logo"
              preview={false}
              height={70}
              width={200}
              style={{ marginRight: '8px' }}
            />
            <Typography.Title level={4} style={{ marginTop: '16px'}}>
              Sales Order {data?.header?.soNumber}
            </Typography.Title>
            <Typography.Title level={5}>
              {data?.header?.allocationStatus}
            </Typography.Title>
            { data?.header?.number && <Barcode value={data?.header?.number} height={30} displayValue={false} /> }
          </Col>
        </Row>
        <Row style={{ marginTop: '8px' }}>
          <Col span={12} style={{ paddingRight: '8px'}}>
            <Card>
              <div>
                <Typography.Text strong type="secondary">Customer:</Typography.Text>
              </div>
              {data?.header?.customer}
              <div>
                <Typography.Text strong type="secondary">Address:</Typography.Text>
              </div>
              {data?.header?.address ? <span>{data.header.address}</span> : <br />}
            </Card>
          </Col>
          <Col span={12} style={{ paddingLeft: '8px'}}>
            <Card>
              <div>
                <Typography.Text strong type="secondary">Internal Instructions:</Typography.Text>
                <br />
                <br />
                <br />
                <br />
              </div>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: '8px' }}>
          <Col style={{ marginLeft: '8px' }}>
            <Space direction="horizontal" size="large">
              <Typography.Text strong type="secondary">Pack weight:</Typography.Text>
              {data?.header?.packWeight}
              <Typography.Text strong type="secondary">Strap:</Typography.Text>
              {data?.header?.strap}
              <Typography.Text strong type="secondary">Wrap:</Typography.Text>
              {data?.header?.wrap}
            </Space>
          </Col>
        </Row>
        <Table
          style={{ marginTop: '8px', minHeight: '40vh' }}
          dataSource={data?.lines}
          columns={columns}
          pagination={false}
          size="small"
        />
        <Divider style={{ margin: '12px 0'}} />
        <Row justify="center">
          <Col style={{ textAlign: 'center' }}>
            <div>
              <Typography.Text>No Returns are available on special ordered / non-stock items</Typography.Text>
            </div>
            <Typography.Title level={4}>*** END OF ORDER ***</Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Descriptions style={{ width: '80%' }} column={1} size="small" bordered>
              <Descriptions.Item label="Sheets" labelStyle={{ fontWeight: 'bold', padding: '4px' }} contentStyle={{ padding: '4px' }}>
                { data?.sheets }
              </Descriptions.Item>
              <Descriptions.Item label="Others" labelStyle={{ fontWeight: 'bold', padding: '4px'}} contentStyle={{ padding: '4px' }}>
                { data?.others }
              </Descriptions.Item>
              <Descriptions.Item label="Weight" labelStyle={{ fontWeight: 'bold', padding: '4px'}} contentStyle={{ padding: '4px' }}>
                { data?.weight }
              </Descriptions.Item>
              <Descriptions.Item label="SQM" labelStyle={{ fontWeight: 'bold', padding: '4px'}} contentStyle={{ padding: '4px' }}>
                { data?.sqm }
              </Descriptions.Item>
              <Descriptions.Item label="Linear Meters" labelStyle={{ fontWeight: 'bold', padding: '4px'}} contentStyle={{ padding: '4px' }}>
                { data?.linearMeters }
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={14}>
            Order Picked by:
            <Divider />
            Received by: (Signed)
            <Divider />
          </Col>
        </Row>
      </div>
    );
  }
}