import {Button, Card, Image, Input, Space, Typography} from "antd";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {getOrderDetails} from "../../service/api";

const ScanOrder = (props) => {
  const inputRef = useRef();
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] =useState(false);

  const fetchOrderDetails = () => {

    // Add validation

    setLoading(true);
    getOrderDetails(code).then(res => {
      setLoading(false);
      if (res.status !== 200) {
        setErrorMessage("Server error. Please try again.")
      } else {
        if (res.data.success) {
          const order = res.data.data.list[0];
          if (order) {
            navigate('/order', { state: { order: order } })
          } else {
            setErrorMessage("Invalid order. Please try again.");
          }
        } else if (res.data.errorCode === '404') {
          setErrorMessage("The barcode/order code is invalid. Please try again.");
        } else {
          setErrorMessage(res.data.errorMessage);
        }
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      setErrorMessage("Network error. Please try again.");
    });
  };

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'all',
    });
  }, []);

  return (
    <div className="content">
      <Card className="content-center">
        <Typography.Title level={2}>
          Please scan the QR code or barcode from the confirmation SMS
        </Typography.Title>
        <Typography.Title level={3}>
          Or manually enter the Order Code
        </Typography.Title>
        <Image
          src="hint_pick.png"
          preview={false}
          height={400}
        />
        <Typography.Title
          level={4}
          type="danger"
        >
          {errorMessage}
        </Typography.Title>
        <Input
          size="large"
          ref={inputRef}
          style={{ maxWidth: '50%' }}
          maxLength={8}
          allowClear
          onChange={(e) => setCode(e.target.value)}
        />
        <div>
          <Space
            style={{ marginTop: '16px' }}
            direction="horizontal"
            size="large"
          >
            <Button
              size="large"
              className="screen-button"
              onClick={() => navigate('/')}
            >Back to Home</Button>
            <Button
              size="large"
              type="primary"
              className="screen-button"
              loading={loading}
              onClick={() => fetchOrderDetails()}
            >Confirm</Button>
          </Space>
        </div>
      </Card>
    </div>
  );
}

export default ScanOrder;