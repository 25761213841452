import {Button, Card, Descriptions, Image, Input, Space, Typography} from "antd";
import {useRef, useState, useEffect} from "react";
import './GetAccount.css';
import {useNavigate} from "react-router";
import {getCustomerByCode} from "../../service/api";

const GetAccount = (props) => {
  const inputRef = useRef();
  const [accountCode, setAccountCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const fetchAccount = () => {
    if (accountCode === '') {
      return;
    }
    setLoading(true);
    getCustomerByCode(accountCode).then(res => {
      setLoading(false);
      if (res.status !== 200) {
        setErrorMessage("Server error. Please try again.")
      } else {
        if (res.data.success) {
          const customer = res.data.data.list[0];
          if (customer) {
            navigate('/create/authenticate', { state: { customer: customer } })
          } else {
            setErrorMessage("Invalid customer code. Please try again.");
          }
        } else if (res.data.errorCode === '404') {
          setErrorMessage("The barcode/order code is invalid. Please try again.");
        } else {
          setErrorMessage(res.data.errorMessage);
        }
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      setErrorMessage("Network error. Please try again.");
    });
  };

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'all',
    });
  }, []);

  return (
    <div className="content">
      <Card
        className="content-center"
        title="Create a New Order"
      >
        <Typography.Title level={2}>
          Please enter your Hume account code
        </Typography.Title>
        <Typography.Title level={4}>
          Or scan the barcode on your membership card
        </Typography.Title>
        <Image
          src="member_card.png"
          preview={false}
          height={300}
        />
        <Typography.Title
          level={4}
          type="danger"
        >
          {errorMessage}
        </Typography.Title>
        <div style={{ marginTop: '12px' }}>
          <Descriptions
            bordered
            className="get-account"
            column={1}>
            <Descriptions.Item label="Account Code">
              <Input
                size="large"
                ref={inputRef}
                maxLength={8}
                allowClear
                onChange={(e) => setAccountCode(e.target.value)}
              />
            </Descriptions.Item>
          </Descriptions>
        </div>
        <div>
          <Space
            style={{ marginTop: '16px' }}
            direction="horizontal"
            size="large"
          >
            <Button
              size="large"
              className="screen-button"
              onClick={() => navigate('/')}
            >Back to Home</Button>
            <Button
              size="large"
              type="primary"
              className="screen-button"
              loading={loading}
              onClick={() => fetchAccount()}
            >Next</Button>
          </Space>
        </div>
      </Card>
    </div>
  )
}

export default GetAccount;