import {
  Breadcrumb,
  Button,
  Card,
  Col, Descriptions,
  Image,
  InputNumber,
  List,
  Row,
  Space,
  Tag,
  Typography
} from "antd";
import {ArrowLeftOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import "./ProductDetails.css";

const ProductDetails = (props) => {

  const {productDetails, loading, group, category, onProductBack, onProductSelectionChanged, productsToAdd} = props;

  const productCardTitle = () => {
    return (
      <Space direction="horizontal" size="large">
        <Button
          className="screen-button-sm"
          size="large" icon={<ArrowLeftOutlined />}
          onClick={() => onProductBack()}
        >Back</Button>
        <Breadcrumb>
          <Breadcrumb.Item>
            {group.description}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {category.description}
          </Breadcrumb.Item>
          {
            productDetails &&
            <Breadcrumb.Item>
              {productDetails.name}
            </Breadcrumb.Item>
          }
        </Breadcrumb>
      </Space>
    )
  };

  return (
    <Card
      loading={loading}
      title={productCardTitle()}
      className="card-slim"
    >
      { productDetails &&
        <Card
          bordered={false}
          type="inner"
          className="card-slim"
          title={
            <>
              <Typography.Title level={4}>{productDetails.name}</Typography.Title>
              <Typography.Title level={5}>{productDetails.code}</Typography.Title>
              <div>
                {productDetails.postingGroup && <Tag>{productDetails.postingGroup.description}</Tag>}
                {productDetails.category && <Tag>{productDetails.category.description}</Tag>}
              </div>
            </>
          }
          extra={
            <Image
              preview={false}
              width={200}
              src={productDetails.imageUrl}
            />
          }
        >
          {
            productDetails.indexProduct &&
            <List
              size="large"
              itemLayout="horizontal"
              pagination={{
                pageSize: 5
              }}
              dataSource={productDetails.childProducts}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <InputNumber
                      key={'input-' + item.id}
                      size="large"
                      controls={false}
                      autoFocus={index === 0}
                      addonBefore={<Button type="text" icon={<MinusOutlined />} onClick={() => onProductSelectionChanged(item, 'MINUS', 1)} /> }
                      addonAfter={<Button type="text" icon={<PlusOutlined />} onClick={() => onProductSelectionChanged(item, 'PLUS', 1)} /> }
                      defaultValue={0}
                      value={productsToAdd.has(item.id) ? productsToAdd.get(item.id).quantity : 0}
                      min={0}
                      max={100}
                      disabled
                    />
                  ]}
                >
                  <List.Item.Meta
                    title={item.name}
                    description={
                      <Space direction="horizontal" size="large">
                        <span>SQM: {item.measurement?.volume}</span>
                        <span>Weight: {item.measurement?.weight} kg</span>
                        <span>Length: {item.measurement?.length}</span>
                      </Space>
                    }
                  />
                  <div>
                    <Tag color={item.stockLevel?.color}>{item.stockLevel?.text}</Tag>
                  </div>
                </List.Item>
              )}
            />
          }
          {
            !productDetails.indexProduct &&
            <Row>
              <Col span={6}>
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="SQM">
                    {productDetails.measurement?.volume}
                  </Descriptions.Item>
                  <Descriptions.Item label="Weight">
                    {productDetails.measurement?.weight} kg
                  </Descriptions.Item>
                  <Descriptions.Item label="Length">
                    {productDetails.measurement?.length}
                  </Descriptions.Item>
                  <Descriptions.Item label="Stock Level">
                    <Tag color={productDetails.stockLevel?.color}>{productDetails.stockLevel?.text}</Tag>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={6} style={{ marginLeft: '16px' }}>
                <InputNumber
                  key={'input-' + productDetails.id}
                  size="large"
                  controls={false}
                  autoFocus
                  addonBefore={<Button type="text" icon={<MinusOutlined />} onClick={() => onProductSelectionChanged(productDetails, 'MINUS', 1)} /> }
                  addonAfter={<Button type="text" icon={<PlusOutlined />} onClick={() => onProductSelectionChanged(productDetails, 'PLUS', 1)} /> }
                  defaultValue={0}
                  value={productsToAdd.has(productDetails.id) ? productsToAdd.get(productDetails.id).quantity : 0}
                  min={0}
                  max={100}
                  disabled
                />
              </Col>
            </Row>
          }
        </Card>
      }
    </Card>
  )
}

export default ProductDetails;