import './App.less';
import {Layout} from "antd";
import NavHeader from "./components/NavHeader";
import NavFooter from "./components/NavFooter";
import HomePage from "./pages/home/HomePage";
import {Route, Routes} from "react-router";
import ScanOrder from "./pages/print/ScanOrder";
import OrderDetails from "./pages/print/OrderDetails";
import GetAccount from "./pages/order/GetAccount";
import Authenticate from "./pages/order/Authenticate";
import CreateOrder from "./pages/order/CreateOrder";
import OrderSuccess from "./pages/order/OrderSuccess";

function App() {
  const { Content } = Layout;

  return (
    <Layout className="app">
      <NavHeader />
      <Content>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/pick" element={<ScanOrder />} />
          <Route exact path="/order" element={<OrderDetails />} />
          <Route exact path="/create/account" element={<GetAccount />} />
          <Route exact path="/create/authenticate" element={<Authenticate />} />
          <Route exact path="/create/order" element={<CreateOrder />} />
          <Route exact path="/create/success" element={<OrderSuccess />} />
        </Routes>
      </Content>
      <NavFooter />
    </Layout>
  );
}

export default App;
