import {useLocation, useNavigate} from "react-router";
import {Alert, Button, notification, Result, Typography} from "antd";
import {PickingNote} from "../print/PickingNote";
import {useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {createPickingNote} from "../../service/api";

const OrderSuccess = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { result } = state;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const componentRef = useRef();
  const doPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    setLoading(true);
    createPickingNote(result.transactionId).then(res => {
      setLoading(false);
      if (res.data.success) {
        setErrorMessage('');
        setData(res.data.data.list[0]);
        setTimeout(() => {
          doPrint();
          notification.open({
            message: 'Printing Picking Note',
            description:
              'The picking note is being printed. Please check the printer.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }, 1000);
      } else {
        setData(null);
        setErrorMessage(res.data.errorMessage);
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      setErrorMessage("Unable to print picking note. Please contact customer service.")
    });
  }

  return (
    <div className="content">
      <Result
        status="success"
        title="Your order has been created successfully."
        subTitle={<Typography.Title level={4}>The order number is <span className='color-primary'>{result.transactionNumber}</span></Typography.Title>}
        extra={[
          <Button key="back"
                  size="large"
                  className="screen-button"
                  onClick={() => navigate('/')}
          >Back to Home</Button>,
          <Button key="print"
                  size="large"
                  type="primary"
                  className="screen-button"
                  loading={loading}
                  onClick={() => handlePrint()}
          >Print</Button>
        ]}
      />
      { errorMessage !== '' &&
        <div style={{ marginTop: '16px' }}>
          <Alert message={errorMessage} type="error" />
        </div>
      }
      <div style={{ display: 'none' }}>
        <PickingNote ref={componentRef} data={data} />
      </div>
    </div>
  )
}

export default OrderSuccess;