import {Alert, Button, Card, Col, Descriptions, notification, Row, Space} from "antd";
import {useLocation, useNavigate} from "react-router";
import {useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import {PickingNote} from "./PickingNote";
import {createPickingNote} from "../../service/api";

const OrderDetails = (props) => {
  const { state } = useLocation();
  const { order } = state;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const componentRef = useRef();
  const doPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    setLoading(true);
    createPickingNote(order.id).then(res => {
      setLoading(false);
      if (res.data.success) {
        setErrorMessage('');
        setData(res.data.data.list[0]);
        setTimeout(() => {
          doPrint();
          notification.open({
            message: 'Printing Picking Note',
            description:
              'The picking note is being printed. Please check the printer.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });
        }, 1000);
      } else {
        setData(null);
        setErrorMessage(res.data.errorMessage);
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      setErrorMessage("Unable to print picking note. Please contact customer service.")
    });
  }

  return (
    <div className="content">
      <Row justify="center">
        <Col>
          <Card>
            <Descriptions
              title={'Sales Order ' + order.orderCode}
              className="order-display"
              bordered
              column={1}
            >
              <Descriptions.Item label="Number">
                {order.number}
              </Descriptions.Item>
              <Descriptions.Item label="Reference">
                {order.reference}
              </Descriptions.Item>
              <Descriptions.Item label="Customer">
                {order.customer}
              </Descriptions.Item>
              <Descriptions.Item label="Branch">
                {order.branch}
              </Descriptions.Item>
              <Descriptions.Item label="SQM">
                {order.sqm}
              </Descriptions.Item>
              <Descriptions.Item label="Weight">
                {order.weight + ' kg'}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {order.status}
              </Descriptions.Item>
            </Descriptions>
            { errorMessage !== '' &&
              <div style={{ marginTop: '16px' }}>
                <Alert message={errorMessage} type="error" />
              </div>
            }
            <div style={{ marginTop: '16px', textAlign: 'center' }}>
              <Space
                direction="horizontal"
                size="large"
              >
                <Button
                  size="large"
                  className="screen-button"
                  onClick={() => navigate('/')}
                >Back to Home</Button>
                <Button
                  size="large"
                  type="primary"
                  className="screen-button"
                  loading={loading}
                  onClick={() => handlePrint()}
                >Print</Button>
              </Space>
            </div>
          </Card>
        </Col>
      </Row>
      <div style={{display: 'none'}}>
        <PickingNote ref={componentRef} data={data} />
      </div>
    </div>
  )
};

export default OrderDetails;