import './HomePage.css';
import {Button, Col, Row, Typography} from "antd";
import {QrcodeOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router";

const HomePage = (props) => {
  const navigate = useNavigate();

  return (
    <div className="content">
      <Row justify="center">
        <Col>
          <Typography.Title level={1}>
            Welcome to My Hume
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col>
          <Button
            className="home-page-button"
            onClick={() => navigate("/create/account")}
          >
            <ShoppingCartOutlined style={{ fontSize: 'xxx-large'}} />
            <div>
              Order Now
            </div>
            <div style={{ fontSize: 'large' }}>
              Skip the queue
            </div>
          </Button>
        </Col>
        <Col>
          <Button
            className="home-page-button"
            onClick={() => navigate("/pick")}
          >
            <QrcodeOutlined style={{ fontSize: 'xxx-large'}} />
            <div>
              Already Ordered?
            </div>
            <div style={{ fontSize: 'large' }}>
              Scan QR code
            </div>
          </Button>
        </Col>
      </Row>
      <div />
    </div>
  )
}

export default HomePage;