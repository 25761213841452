import './Authenticate.css';
import {Button, Card, Descriptions, Input, Space, Typography, message} from "antd";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router";
import {authenticate, getVerificationCode} from "../../service/api";

const Authenticate = (props) => {
  const [accountPin, setAccountPin] = useState('');
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [mobileStatus, setMobileStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sendButtonText, setSendButtonText] = useState('Send');
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { customer } = state;

  const onSendVerificationCode = () => {
    if (mobile === '' || mobile.length !== 10 || !mobile.startsWith('04')) {
      setMobileStatus('error');
      setErrorMessage('Invalid mobile number.');
      return;
    } else {
      setMobileStatus('');
      setErrorMessage('');
    }
    setSendButtonDisabled(true);
    setSendButtonText('Sending');
    const data = {
      mobile: mobile,
      customerId: customer.id
    };
    getVerificationCode(data).then(res => {
      if (res.status !== 200) {
        setErrorMessage("Invalid phone number for the account. Please contact Customer Service.")
        setSendButtonDisabled(false);
        setSendButtonText("Send");
      } else {
        if (!res?.data?.success) {
          setErrorMessage(res.data.errorMessage);
          setSendButtonDisabled(false);
          setSendButtonText("Send");
        } else {
          setErrorMessage("");
          setSendButtonText("Sent");
          message.success('A verification code has been sent to the mobile number provided.')
        }
      }
    }).catch(err => {
      setSendButtonDisabled(false);
      setSendButtonText("Send");
      console.error(err);
      setErrorMessage("Network error. Please try again.");
    });
  };

  const onAuthenticate = () => {
    const data = {
      accountCode: customer.code,
      pin: accountPin,
      code: code,
      mobile: mobile
    };
    setLoading(true);
    authenticate(data).then(res => {
      setLoading(false);
      if (res.status !== 200) {
        setErrorMessage("Invalid account PIN or verification code.")
      } else {
        navigate('/create/order', { state: { user: res.data.data.list[0] } });
      }
    }).catch(err => {
      setLoading(false);
      console.error(err);
      setErrorMessage("Network error. Please try again.");
    });
  }

  return (
    <div className="content">
      <Card
        className="content-center"
        title="Create a New Order"
        style={{ maxWidth: '70vw'}}
      >
        <div style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}>
          <Typography.Title level={4}>
            Account Name:
          </Typography.Title>
          <Typography.Text className="color-primary">
            {`${customer?.code} - ${customer?.name}`}
          </Typography.Text>
        </div>
        <Card.Grid className="authenticate">
          <Typography.Title level={4}>
            Hume account PIN authentication
          </Typography.Title>
          <Typography.Title level={5}>
            Your account PIN is a 4-digit number
          </Typography.Title>
          <div style={{ marginTop: '12px' }}>
            <Descriptions
              bordered
              className="authenticate"
              column={1}>
              <Descriptions.Item label="PIN">
                <Input.Password
                  size="large"
                  maxLength={4}
                  allowClear
                  onChange={(e) => setAccountPin(e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card.Grid>
        <Card.Grid className="authenticate">
          <Typography.Title level={4}>
            SMS verification code
          </Typography.Title>
          <Typography.Title level={5}>
            Please enter your mobile number
          </Typography.Title>
          <div style={{ marginTop: '12px' }}>
            <Descriptions
              bordered
              className="authenticate"
              column={1}>
              <Descriptions.Item label="Mobile">
                <Input
                  size="large"
                  className="compact"
                  maxLength={10}
                  allowClear
                  onChange={(e) => setMobile(e.target.value)}
                  style={{ maxWidth: '80%'}}
                  status={mobileStatus}
                />
                <Button size="large"
                        onClick={() => onSendVerificationCode()}
                        disabled={sendButtonDisabled}
                >
                  {sendButtonText}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="Code">
                <Input
                  size="large"
                  maxLength={6}
                  allowClear
                  onChange={(e) => setCode(e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card.Grid>
        {
          errorMessage !== '' &&
          <div style={{ width: '100%', marginTop: '32px' }}>
            <Typography.Title
              level={4}
              type="danger"
            >
              {errorMessage}
            </Typography.Title>
          </div>
        }
        <div style={{ width: '100%', marginTop: '32px', marginBottom: '32px' }}>
          <Space
            style={{ marginTop: '16px' }}
            direction="horizontal"
            size="large"
          >
            <Button
              size="large"
              className="screen-button"
              onClick={() => navigate('/')}
            >Back to Home</Button>
            <Button
              size="large"
              type="primary"
              className="screen-button"
              loading={loading}
              onClick={() => onAuthenticate()}
            >Next</Button>
          </Space>
        </div>
      </Card>
    </div>
  )
}

export default Authenticate;