import {Breadcrumb, Button, Card, Image, List, message, Modal, Space, Typography} from "antd";
import {useEffect, useState} from "react";
import {
  getProductCategories, getProductDetails,
  getProductGroups,
  getProductsByCategory,
  getProductsBySystem,
  getProductSystems
} from "../../service/api";
import {ArrowLeftOutlined} from "@ant-design/icons";
import ProductDetails from "./ProductDetails";

const ModalProduct = (props) => {
  const { visible, setVisible, onAddProducts } = props;
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [systems, setSystems] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSystem, setSelectedSystem] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [navigation, setNavigation] = useState('G');
  const [productsToAdd, setProductsToAdd] = useState(new Map());
  const [productDetails, setProductDetails] = useState();


  const onGroupClicked = (group) => {
    setSelectedGroup(group);
    if (group.useSystem === true) {
      loadSystems(group.id);
    } else {
      loadCategories(group.id);
    }
  };

  const onCategoryClicked = (category) => {
    setSelectedCategory(category);
    setSelectedSystem(undefined);
    loadProductsByCategory(category);
  };

  const onSystemClicked = (system) => {
    setSelectedCategory(undefined);
    setSelectedSystem(system);
    loadProductsBySystem(system);
  };

  const onProductClicked = (product) => {
    setSelectedProduct(product);
    setNavigation('PD');
    loadProductDetails(product);
  }

  const loadGroups = () => {
    setLoading(true);
    getProductGroups().then(res => {
      setLoading(false);
      if (res.status === 200) {
        setGroups(res.data.data.list);
      } else {
        message.error("An error has occurred. Please contact customer service.");
        setGroups([]);
      }
    }).catch(err => {
      setLoading(false);
      console.log(err);
      message.error("Error communicating with server. Please try again.");
      setGroups([]);
    });
  };

  const loadCategories = (groupId) => {
    setLoading(true);
    getProductCategories(groupId).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setCategories(res.data.data.list);
        setNavigation('C');
      } else {
        message.error("An error has occurred. Please contact customer service.");
        setCategories([]);
      }
    }).catch(err => {
      setCategories([]);
      setLoading(false);
      console.log(err);
      message.error("Error communicating with server. Please try again.");
    });
  };

  const loadSystems = (groupId) => {
    setLoading(true);
    getProductSystems(groupId).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setSystems(res.data.data.list);
        setNavigation('S');
      } else {
        message.error("An error has occurred. Please contact customer service.");
        setSystems([]);
      }
    }).catch(err => {
      setSystems([]);
      setLoading(false);
      console.log(err);
      message.error("Error communicating with server. Please try again.");
    });
  };

  const loadProductsByCategory = (category) => {
    setLoading(true);
    getProductsByCategory(category.id).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setProducts(res.data.data.list);
        setNavigation('P');
      } else {
        message.error("An error has occurred. Please contact customer service.");
        setProducts([]);
      }
    }).catch(err => {
      setProducts([]);
      setLoading(false);
      console.log(err);
      message.error("Error communicating with server. Please try again.");
    });
  };

  const loadProductsBySystem = (system) => {
    setLoading(true);
    getProductsBySystem(system.id).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setProducts(res.data.data.list);
        setNavigation('P');
      } else {
        message.error("An error has occurred. Please contact customer service.");
        setProducts([]);
      }
    }).catch(err => {
      setProducts([]);
      setLoading(false);
      console.log(err);
      message.error("Error communicating with server. Please try again.");
    });
  };

  const loadProductDetails = (product) => {
    setLoading(true);
    getProductDetails(product.id).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setProductDetails(res.data.data.list[0]);
        constructProductsToAdd(res.data.data.list[0]);
      } else {
        message.error("An error has occurred. Please contact customer service.");
        setProductDetails(undefined);
      }
    }).catch(err => {
      setProductDetails(undefined);
      setLoading(false);
      console.log(err);
      message.error("Error communicating with server. Please try again.");
    });
  };

  const constructProductsToAdd = (product) => {
    const pta = new Map();
    if (product.indexProduct) {
      product.childProducts.forEach(p => pta.set(p.id, {
        product: p,
        quantity: 0
      }))
    } else {
      pta.set(product.id, {
        product: product,
        quantity: 0
      })
    }
    setProductsToAdd(pta);
  };

  const onCategorySystemBack = () => {
    setSelectedGroup(undefined);
    setNavigation('G');
  };

  const onProductListBack = () => {
    setSelectedProduct(undefined);
    if (selectedCategory) {
      setNavigation('C');
    } else {
      setNavigation('S');
    }
  };

  const onProductBack = () => {
    setNavigation('P');
  };

   const onProductSelectionChanged = (product, type, quantity) => {
     const ptas = new Map(productsToAdd);
     switch (type) {
       case 'PLUS':
         if (ptas.has(product.id)) {
           let pta = ptas.get(product.id);
           ptas.set(product.id, {
             product: product,
             quantity: pta.quantity + quantity
           });
         } else {
           ptas.set(product.id, {
             product: product,
             quantity: quantity
           });
         }
         break;
       case 'MINUS':
         if (ptas.has(product.id)) {
           let pta = ptas.get(product.id);
           let newQty = pta.quantity - quantity;
           ptas.set(product.id, {
             product: product,
             quantity: newQty < 0 ? 0 : newQty
           });
         }
         break;
       case 'SET':
         ptas.set(product.id, {
           product: product,
           quantity: quantity
         });
         break;
       default:
         break;
     }
     setProductsToAdd(ptas);
   };

   const onSubmitProducts = () => {
     const filteredProducts = [];
     productsToAdd.forEach((value, _key, _map) => {
       if (value.quantity > 0) {
         filteredProducts.push(value);
       }
     });
     if (filteredProducts.length > 0) {
      onAddProducts(filteredProducts);
      onProductBack();
     }
   };

  const categorySystemCardTitle = () => {
    return (
      <Space direction="horizontal" size="large">
        <Button
          className="screen-button-sm"
          size="large" icon={<ArrowLeftOutlined />}
          onClick={() => onCategorySystemBack()}
        >Back</Button>
        <Breadcrumb>
          <Breadcrumb.Item>
            {selectedGroup.description}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Select sub-category
          </Breadcrumb.Item>
        </Breadcrumb>
      </Space>
    )
  };

  const productListCardTitle = () => {
    return (
      <Space direction="horizontal" size="large">
        <Button
          className="screen-button-sm"
          size="large" icon={<ArrowLeftOutlined />}
          onClick={() => onProductListBack()}
        >Back</Button>
        <Breadcrumb>
          <Breadcrumb.Item>
            {selectedGroup.description}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {selectedCategory && selectedCategory.description}
            {selectedSystem && selectedSystem.description}
          </Breadcrumb.Item>
        </Breadcrumb>
      </Space>
    )
  }

  useEffect(() => {
    loadGroups();
  }, []);

  return (
    <Modal
      title="Add Products"
      visible={visible}
      onCancel={() => setVisible(false)}
      onOk={() => onSubmitProducts()}
      width={'80vw'}
      style={{ minHeight: '80vh', top: 20 }}
      maskClosable={false}
      confirmLoading={loading}
      okButtonProps={{
        size: 'large',
        className: 'screen-button-sm'
      }}
      cancelButtonProps={{
        size: 'large',
        className: 'screen-button-sm'
      }}
      forceRender
    >
      {
        navigation === 'G' &&
        <Card
          title="Please select from one of the categories:"
          loading={loading}
          className="card-slim"
        >
          { groups.map(group => (
            <Card.Grid
              key={group.id}
              onClick={() => onGroupClicked(group)}
            >
              <Image
                preview={false}
                height={120}
                width={"100%"}
                src={group.image}
                fallback="https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/image_placeholder.jpg"
              />
              <Typography.Title level={4}>
                {group.description}
              </Typography.Title>
            </Card.Grid>
          ))}
        </Card>
      }
      {
        navigation === 'C' &&
        <Card
          title={categorySystemCardTitle()}
          loading={loading}
          className="card-slim"
        >
          { categories.map(category => (
            <Card.Grid
              key={category.id}
              onClick={() => onCategoryClicked(category)}
            >
              <Image
                preview={false}
                height={120}
                width={"100%"}
                src={category.photo}
                fallback="https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/image_placeholder.jpg"
              />
              <Typography.Title level={4}>
                {category.description}
              </Typography.Title>
            </Card.Grid>
          ))}
        </Card>
      }
      {
        navigation === 'S' &&
        <Card
          title={categorySystemCardTitle()}
          loading={loading}
          className="card-slim"
        >
          { systems.map(system => (
            <Card.Grid
              key={system.id}
              onClick={() => onSystemClicked(system)}
            >
              <Image
                preview={false}
                height={120}
                width={"100%"}
                src={system.image}
                fallback="https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/image_placeholder.jpg"
              />
              <Typography.Title level={4}>
                {system.description}
              </Typography.Title>
            </Card.Grid>
          ))}
        </Card>
      }
      {
        navigation === 'P' &&
        <Card
          title={productListCardTitle()}
          loading={loading}
          className="card-slim"
        >
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              pageSize: 8,
              showSizeChanger: false
            }}
            dataSource={products}
            renderItem={item => (
              <List.Item
                key={item.id}
                extra={
                <Image
                  preview={false}
                  height={120}
                  src={'https://hume-product-image.s3.ap-southeast-2.amazonaws.com/' + item.code + '.jpg'}
                  fallback="https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/image_placeholder.jpg"
                />
                }
                onClick={(_) => onProductClicked(item)}
              >
                <List.Item.Meta
                  title={item.description}
                  description={item.code}
                />
              </List.Item>
            )}
          />
        </Card>
      }
      {
        navigation === 'PD' &&
        <ProductDetails
          productsToAdd={productsToAdd}
          group={selectedGroup}
          category={selectedCategory ? selectedCategory : selectedSystem}
          onProductBack={onProductBack}
          onProductSelectionChanged={onProductSelectionChanged}
          productDetails={productDetails}
          loading={loading}
        />
      }
    </Modal>
  )
}

export default ModalProduct;